import React, { Component } from 'react';
import { graphql } from 'gatsby';

import FoFComponent from '../components/fof/FoF';

export const query = graphql`
  query ($id: String!) {
    sanityFofPage(id: { eq: $id }) {
      id
      title {
        languagePrimary
        languageSecondary
      }
    }
  }
`;

class FoF extends Component {
  render() {
    const { site } = this.props;
    const { sanityFofPage } = this.props.data;

    return <FoFComponent fofPage={sanityFofPage} site={site} />;
  }
}

export default FoF;
