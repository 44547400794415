import React, { Component } from 'react';

import Link from '../Link';
import Logos from '../_common/Logos';
import { getCmsValue } from '../../utils/utils';

class FoF extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { fofPage, site } = this.props;

    return (
      <article className="page fof">
        <h1>{getCmsValue(fofPage.title, site)}</h1>
      </article>
    );
  }
}

export default FoF;
